import {
  FormattedHTMLMessage,
  FormattedMessage,
  injectIntl
} from "gatsby-plugin-intl";
import React from "react";
import Helmet from "react-helmet";
import Container from "../components/Container";
import FooterBackground from "../components/FooterBackground";
import {
  circleSmall,
  hardware1, hardware1_2x,
  hardware2, hardware2_2x,
  hardware3, hardware3_2x,
  hardware4, hardware4_2x,
  hardware5, hardware5_2x,
  loader,
  target,
  waveHorizontalLarge,
  waveHorizontalSmall,
  waveVerticalLarge,
  waveVerticalSmall
} from "../components/Images";
import Layout from "../components/Layout";
import Row from "../components/Row";
import CTA from "../partials/CTA";
import styled from "../styling/styled";
import { theme } from "../styling/theme";

const Content = styled.div`
  padding-top: 42px;
  padding-bottom: 42px;
  ${[theme.media.maxM]} {
    padding-top: 15px;
    padding-bottom: 15px;
  }
`;

const Content1 = styled(Content)`
  background-size: 160px 17px;
  background-image: url(${waveHorizontalLarge}), url(${waveHorizontalLarge});
  background-repeat: no-repeat;
  background-position: left calc(${theme.container.background} - 240px) top
      115px,
    right calc(${theme.container.background} - 180px) bottom 70px;
  ${[theme.media.maxM]} {
    padding-top: 75px;
  }
`;

const Content2 = styled(Content)`
  background-size: 17px 17px;
  background-image: url(${circleSmall});
  background-repeat: no-repeat;
  background-position: left calc(${theme.container.background} - 100px) top 75px;
`;

const Content3 = styled(Content)`
  background-size: 17px 17px, 17px 47px;
  background-image: url(${circleSmall}), url(${waveVerticalSmall});
  background-repeat: no-repeat;
  background-position: right calc(${theme.container.background} - 160px) top
      75px,
    left calc(${theme.container.background} - 100px) top 95px;
`;

const Content4 = styled(Content)`
  background-size: 30px 30px, 17px 17px;
  background-image: url(${loader}), url(${circleSmall});
  background-repeat: no-repeat;
  background-position: right calc(${theme.container.background} + 60px) top 45px,
    left calc(${theme.container.background} - 70px) bottom 5px;
`;

const Content6 = styled(Content)`
  padding-bottom: 175px;
  ${[theme.media.maxM]} {
    padding-bottom: 100px;
  }
  background-size: 17px 160px, 28px 28px, 47px 17px;
  background-image: url(${waveVerticalLarge}), url(${target}),
    url(${waveHorizontalSmall});
  background-repeat: no-repeat;
  background-position: left calc(${theme.container.background} - 140px) top,
    left calc(${theme.container.background} - 20px) bottom 180px,
    right calc(${theme.container.background} - 100px) top 40px;
  ${[theme.media.maxM]} {
    padding-top: 75px;
  }
`;

const RowHardware = styled(Row)`
  align-items: center;

  ${[theme.media.maxM]} {
    align-items: flex-start;
  }
`;

const RowHeader = styled(RowHardware)`
  position: relative;
`;

const ColText = styled.div`
  max-width: calc(50% + 50px);
  flex-basis: calc(50% + 50px);
  ${[theme.media.maxM]} {
    max-width: 80%;
    flex-basis: 80%;
  }
`;

const ColProductText = styled(ColText)`
  ${[theme.media.maxM]} {
    max-width: 100%;
    flex-basis: 100%;
  }
`;

const ColImage = styled.div`
  max-width: calc(50% - 50px);
  flex-basis: calc(50% - 50px);
  ${[theme.media.maxM]} {
    max-width: 20%;
    flex-basis: 20%;
  }
`;

const ColProductImage = styled(ColImage)`
  ${[theme.media.maxM]} {
    max-width: 320px;
    flex-basis: 320px;
    order: -1;
    margin-bottom: 30px;
  }
`;

const ImageLeft = styled.img`
  display: block;
  translate: -20px 0;
  ${[theme.media.maxM]} {
    translate: 0;
  }
`;
const ImageRight = styled.img`
  display: block;
  margin-left: 50px;
  ${[theme.media.maxM]} {
    margin-left: 0;
  }
`;

const ImageHeader = styled.img`
  ${[theme.media.maxM]} {
    position: absolute;
    top: -80px;
    right: -80px;
    width: 50vw;
    z-index: -1;
  }
  ${[theme.media.maxS]} {
    right: 0px;
  }
`;

class HardwarePage extends React.Component {
  render() {
    const intl = this.props.intl;
    return (
      <>
        <Helmet
          title={
            intl.formatMessage({ id: "pages.hardware.title" }) +
            " | Eledio.com"
          }
          meta={[
            {
              name: "description",
              content: intl.formatMessage({ id: "pages.hardware.description" })
            },
            {
              property: "og:title",
              content: intl.formatMessage({ id: "pages.hardware.title" })
            },
            {
              property: "og:description",
              content: intl.formatMessage({ id: "pages.hardware.description" })
            }
          ]}
        >
          <html lang={intl.locale} />
        </Helmet>
        <Layout>
          <Content1>
            <Container>
              <RowHeader spacing={15}>
                <ColText>
                  <h1>
                    <FormattedMessage id="pages.hardware.title" />
                  </h1>
                  <p>
                    <FormattedHTMLMessage id="pages.hardware.text" />
                  </p>
                </ColText>
                <ColImage>
                  <ImageRight
                      src={hardware1}
                      srcSet={`${hardware1}, ${hardware1_2x} 2x`}
                      alt="Eledio Gateway image"
                      style={{
                        scale: "0.9"
                      }}
                  />
                </ColImage>
              </RowHeader>
            </Container>
          </Content1>
          <Content2>
            <Container>
              <RowHardware spacing={15}>
                <ColProductImage>
                  <ImageLeft
                    src={hardware2}
                    srcSet={`${hardware2}, ${hardware2_2x} 2x`}
                    alt="Eledio Gateway image"
                    style={{
                      scale: "1.15"
                    }}
                  />
                </ColProductImage>
                <ColProductText>
                  <h3>
                    <FormattedMessage id="pages.hardware.titleGateways" />
                  </h3>
                  <p>
                    <FormattedHTMLMessage id="pages.hardware.textGateways1" />
                  </p>
                  <p>
                    <FormattedMessage id="pages.hardware.textGateways2" />
                  </p>
                  <p>
                    <FormattedMessage id="pages.hardware.textGateways3" />
                  </p>
                  <p>
                    <FormattedMessage id="pages.hardware.textGateways4" />
                  </p>
                </ColProductText>
              </RowHardware>
            </Container>
          </Content2>
          <Content3>
            <Container>
              <RowHardware spacing={15}>
                <ColProductText>
                  <h3>
                    <FormattedMessage id="pages.hardware.titlePeripherals" />
                  </h3>
                  <p>
                    <FormattedHTMLMessage id="pages.hardware.textPeripherals1" />
                  </p>
                  <p>
                    <FormattedHTMLMessage id="pages.hardware.textPeripherals2" />
                  </p>
                </ColProductText>
                <ColProductImage>
                  <ImageRight
                    src={hardware3}
                    srcSet={`${hardware3}, ${hardware3_2x} 2x`}
                    alt="Eledio Devices"
                  />
                </ColProductImage>
              </RowHardware>
            </Container>
          </Content3>
          <Content4>
            <Container>
              <RowHardware spacing={15}>
                <ColProductImage>
                  <ImageLeft
                    src={hardware4}
                    srcSet={`${hardware4}, ${hardware4_2x} 2x`}
                    alt="Eledio Power Metering Unit"
                    style={{
                      scale: "1.15"
                    }}
                  />
                </ColProductImage>
                <ColProductText>
                  <h3>
                    <FormattedMessage id="pages.hardware.titleSensors" />
                  </h3>
                  <p>
                    <FormattedHTMLMessage id="pages.hardware.textSensors1" />
                  </p>
                  <p>
                    <FormattedHTMLMessage id="pages.hardware.textSensors2" />
                  </p>
                </ColProductText>
              </RowHardware>
            </Container>
          </Content4>
          <Content>
            <Container>
              <RowHardware spacing={15}>
                <ColProductText>
                  <h3>
                    <FormattedMessage id="pages.hardware.titleDevices" />
                  </h3>
                  <p>
                    <FormattedHTMLMessage id="pages.hardware.textDevices1" />
                  </p>
                  <p>
                    <FormattedHTMLMessage id="pages.hardware.textDevices2" />
                  </p>
                </ColProductText>
                <ColProductImage>
                  <ImageRight
                    src={hardware5}
                    srcSet={`${hardware5}, ${hardware5_2x} 2x`}
                    alt="3rd Party Devices"
                  />
                </ColProductImage>
              </RowHardware>
            </Container>
          </Content>
          <CTA
            buttonLink="/contact/"
            buttonText={intl.formatMessage({ id: "pages.hardware.ctatext" })}
            claim={intl.formatMessage({ id: "pages.hardware.ctaclaim" })}
          />
          <FooterBackground />
        </Layout>
      </>
    );
  }
}

export default injectIntl(HardwarePage);
